import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import { HomePage } from "pages/Public/HomePage";
import { SignInPage } from "pages/Public/SignInPage";
import { AdminLogin } from "pages/admin/AdminLogin";
import { EmailVerification } from "pages/Public/EmailVerification";
import { SignUpPage } from "pages/Public/SignUpPage";
import { CookieNotice, Privacy, Terms } from "pages/Public/Policy";
import { ContactUsPage } from "pages/Public/ContactUsPage";
import { ForgotPasswordPage } from "pages/Public/ForgotPassword";
import { ResetPasswordPage } from "pages/Public/ResetPasswordPage";
import { UnsubscribePage } from "pages/Public/UnsubscribePage";
import { AboutUs } from "pages/Public/AboutUs";
import { PlatformPage } from "pages/Public/PlatformPage";
import { News1 } from "pages/Public/_news/News1";
import { News2 } from "pages/Public/_news/News2";
import { News3 } from "pages/Public/_news/News3";
import { AdminPage } from "pages/admin/AdminPage";
import { ManagePlans } from "pages/admin/ManagePlans";
import { ManageUsers } from "pages/admin/ManageUsers";
import { ManageCoupons } from "pages/admin/ManageCoupons";
import { ManageProjects } from "pages/admin/ManageProjects";
import { ManageContactUs } from "pages/admin/ManageContactUs";
import { ManageFeedback } from "pages/admin/ManageFeedback";
import { LoginActivity } from "pages/admin/LoginActivity";
import { ManageOrders } from "pages/admin/ManageOrders";
import { ManageBills } from "pages/admin/ManageBills";
import { SingleSearchPage } from "pages/Private/SingleSearch";
import { ProjectsList } from "pages/Private/ProjectsList";
import { SettingsPage } from "pages/Private/SettingsPage";
import { ChangePasswordPage } from "pages/Private/ChangePasswordPage";
import { APITestPage } from "pages/Private/APITestPage";
import { CompletedProjectPage } from "pages/Private/CompletedProjectPage";
import { AddressViewPage } from "pages/Private/AddressView";
import { PdfExport } from "pages/Private/PdfExport";
import { NotFoundPage } from "pages/Private/NotFoundPage";
import { CreatedProjectPage } from "pages/Private/CreatedProjectPage";

import { PublicRoute, BaseRoute, PrivateRoute, AdminRoute } from "./route-guards";
import { ROUTE_PATHS } from "./consts";
import { CountryProvider, FiltersProvider, MapProvider } from "../business";

export default function AppRouter() {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <PublicRoute path="/" exact component={HomePage} />
        <PublicRoute path={ROUTE_PATHS.HOME} component={HomePage} />

        <BaseRoute path={ROUTE_PATHS.ADMIN_LOGIN} exact component={AdminLogin} />
        <BaseRoute path={ROUTE_PATHS.SIGN_IN} component={SignInPage} />
        <BaseRoute path={ROUTE_PATHS.VERIFY_EMAIL} component={EmailVerification} />
        <BaseRoute path={ROUTE_PATHS.SIGN_UP} component={SignUpPage} />
        <BaseRoute path={ROUTE_PATHS.COOKIE_NOTICE} component={CookieNotice} />
        <BaseRoute path={ROUTE_PATHS.PRIVACY} component={Privacy} />
        <BaseRoute path={ROUTE_PATHS.TERMS} component={Terms} />
        <BaseRoute path={ROUTE_PATHS.CONTACT_US} component={ContactUsPage} />
        <BaseRoute path={ROUTE_PATHS.FORGOT_PASSWORD} component={ForgotPasswordPage} />
        <BaseRoute path={ROUTE_PATHS.RESET_PASSWORD} component={ResetPasswordPage} />
        <BaseRoute path={ROUTE_PATHS.UNSUBSCRIBE} component={UnsubscribePage} />
        <BaseRoute path={ROUTE_PATHS.ABOUT_US} component={AboutUs} />
        <BaseRoute path={ROUTE_PATHS.PLATFORM} component={PlatformPage} />
        <BaseRoute path={ROUTE_PATHS.NEWS_1} component={News1} />
        <BaseRoute path={ROUTE_PATHS.NEWS_2} component={News2} />
        <BaseRoute path={ROUTE_PATHS.NEWS_3} component={News3} />
        <BaseRoute exact path={ROUTE_PATHS.LINKEDIN} component={LinkedInCallback} />

        <AdminRoute path={ROUTE_PATHS.ADMIN} exact component={AdminPage} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_PLANS} exact component={ManagePlans} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_USERS} exact component={ManageUsers} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_COUPONS} exact component={ManageCoupons} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_PROJECTS} exact component={ManageProjects} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_CONTACT_US} exact component={ManageContactUs} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_FEEDBACK} exact component={ManageFeedback} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_LOGIN_ACTIVITY} exact component={LoginActivity} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_ORDERS} exact component={ManageOrders} />
        <AdminRoute path={ROUTE_PATHS.ADMIN_BILLS} exact component={ManageBills} />

        <CountryProvider>
          <FiltersProvider>
            <MapProvider>
              <PrivateRoute path={ROUTE_PATHS.SINGLE_SEARCH} component={SingleSearchPage} />
              <PrivateRoute path={ROUTE_PATHS.PROJECTS} component={ProjectsList} />
              <PrivateRoute path={ROUTE_PATHS.SETTINGS} component={SettingsPage} />
              <PrivateRoute path={ROUTE_PATHS.CHANGE_PASSWORD} component={ChangePasswordPage} />
              <PrivateRoute path={ROUTE_PATHS.API_TEST} component={APITestPage} />

              <PrivateRoute
                path={ROUTE_PATHS.CREATED_PROJECT({ projectId: ":projectId", projectType: ":projectType" })}
                component={CreatedProjectPage}
              />

              <PrivateRoute
                path={ROUTE_PATHS.COMPLETED_PROJECT({
                  projectId: ":projectId",
                  projectType: ":projectType",
                  tab: ":tab",
                })}
                component={CompletedProjectPage}
              />

              <PrivateRoute
                path={ROUTE_PATHS.ADDRESS_VIEW_PAGE({
                  projectId: ":projectId",
                  addressId: ":addressId",
                  buildingNum: ":buildingNum",
                })}
                component={AddressViewPage}
              />

              <PrivateRoute
                path={ROUTE_PATHS.PDF_EXPORT({
                  projectType: ":projectType",
                  projectId: ":projectId",
                  addressId: ":addressId",
                })}
                component={PdfExport}
              />
            </MapProvider>
          </FiltersProvider>
        </CountryProvider>

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}
