import { env } from "configs/env";

export let tracker = null;

export async function startTracker(startOpts = {}) {
  if (typeof window === "undefined" || env.NODE_ENV === "test") {
    console.warn("OpenReplay tracker is not initialized due to environment.");
    return;
  }

  if (!tracker) {
    try {
      const { default: Tracker } = await import("@openreplay/tracker");
      tracker = new Tracker({
        projectKey: env.OPENREPLAY_PROJECT_KEY,
        ingestPoint: env.OPENREPLAY_URL,
        __DISABLE_SECURE_MODE: env.OPENREPLAY_INSECURE,
      });
    } catch (error) {
      console.error("Failed to dynamically import OpenReplay tracker:", error);
      return;
    }
  }

  try {
    await tracker.start(startOpts);
  } catch (error) {
    console.error("Failed to start OpenReplay tracker:", error);
  }
}

export function stopTracker() {
  if (!tracker) return;
  tracker.stop();
}
