import { BOX_SHADOW, COLORS, FONTS, WEIGHT, singleSearchMobileQuery } from "configs/styles/common";

const media = `@media ${singleSearchMobileQuery}`;

export const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    maxWidth: 510,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: BOX_SHADOW.REGULAR,
    cursor: "pointer",
    border: "none",
    outline: state.isFocused ? `2px solid ${COLORS.DARK.Main}` : "none",
    borderRadius: 6,
    minHeight: state.selectProps.height,
    backgroundColor: state.selectProps.isError ? COLORS.INPUT.ErrorBg : COLORS.LIGHT.White,
    [media]: {
      minHeight: state.selectProps.mobileHeight,
    },
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 16,
    cursor: "pointer",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.selectProps.isError ? COLORS.INPUT.AccentPlaceholder : COLORS.INPUT.Placeholder,
    textTransform: "capitalize",
    fontWeight: WEIGHT.regular,
    fontFamily: FONTS.Main,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "1rem",
    borderBottom: "1px solid",
    borderColor: state.selectProps.isError ? COLORS.INPUT.AccentPlaceholder : COLORS.INPUT.Placeholder,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isError ? COLORS.INPUT.AccentPlaceholder : COLORS.INPUT.Placeholder,
    marginTop: "10px",
    marginBottom: "10px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    svg: {
      path: {
        fill: state.isFocused ? COLORS.ACCENT : COLORS.DARK.Secondary,
      },
    },
    transform: state.isFocused && "rotate(180deg)",
  }),
  multiValue: (provided) => ({
    ...provided,
    maxWidth: 80,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    height: 25,
    paddingTop: 2,
    alignItems: "center",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    height: 25,
    paddingTop: 2,
    alignItems: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 400,
    fontFamily: FONTS.Main,
    color: COLORS.DARK.Main,
    fontSize: "1rem",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "5px",
    boxShadow: BOX_SHADOW.LIGHT,
    borderRadius: "6px",
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0",
    overflowX: "hidden",
    color: COLORS.DARK.Main,
    maxHeight: 200,
  }),
};
