import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import { ROUTE_PATHS } from "app/providers/router";
import { showSuccessToast } from "services/utils/toast";
import { stopTracker } from "services/tracking/openreplayTracker";

import { logout } from "./userAPI";

export function useSignOut(successMessage) {
  const history = useHistory();

  const { mutate: signOut } = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      Cookies.remove("isLogin");
      showSuccessToast(successMessage);
      stopTracker();
      history.push(ROUTE_PATHS.HOME);
    },
  });

  return { signOut };
}
