import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { queryKeys } from "app/providers/react-query";
import { showSuccessToast } from "services/utils/toast";

import { updateUserInfo } from "./userAPI";

export function useUpdateUserInfo() {
  const { t } = useTranslation("profilePage");
  const queryClient = useQueryClient();

  const { mutate: updateUser } = useMutation({
    mutationFn: updateUserInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.customer],
      });
      showSuccessToast(t("User is updated successfully!"));
    },
  });

  return { updateUser };
}
