import { QueryClient } from "@tanstack/react-query";

import i18n from "configs/i18n/i18n";
import { showErrorToast } from "services/utils/toast";

import { getTitleBasedOnError } from "./helpers";

function queryErrorHandler(error) {
  const title = getTitleBasedOnError(error);

  i18n.loadNamespaces(["translation"], () => {
    showErrorToast(i18n.t(title));
  });
}

export function generateQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        onError: queryErrorHandler,
        retry: false,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 1000 * 60,
      },
      mutations: {
        onError: queryErrorHandler,
        retry: false,
      },
    },
  });
}

export const queryClient = generateQueryClient();
