import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { COLORS, ModalButton, ModalText, ModalTitle, ModalWrapper } from "configs/styles/common";
import { CheckSVG } from "assets/images/modals";
import { Modal } from "components/presenters/common/Modal";

const TickCircle = styled.div`
  position: absolute;
  top: -70px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  background-color: ${COLORS.ACCENT};
  background-image: url(${CheckSVG});
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
`;

function SuccessModalContent({ close, text }) {
  const { t, i18n } = useTranslation("auth");

  return (
    <ModalWrapper>
      <TickCircle />
      <ModalTitle style={{ marginTop: "32px" }} lang={i18n.language} data-testid="success-title">
        {t("Success!")}
      </ModalTitle>
      <ModalText>{text}</ModalText>
      <ModalButton onClick={close} lang={i18n.language} data-testid="success-btn">
        {t("Back")}
      </ModalButton>
    </ModalWrapper>
  );
}

export function SuccessModal({ close, open, text }) {
  return <Modal content={SuccessModalContent} open={open} close={close} text={text} modalWidth="36%" isNoBackground />;
}
