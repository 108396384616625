import { formatDistanceToNow, formatDuration } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { useLocale } from "services/hooks/useLocale";
import { openTidioChat } from "services/utils/tidioChat";
import { ROUTE_PATHS } from "app/providers/router";

import { getMessage, getStatus, processStatuses } from "../../helpers";
import * as Styled from "../../ui/style";

export function NotificationItem({ notification }) {
  const [locale] = useLocale();

  const history = useHistory();
  const { i18n, t } = useTranslation();

  const {
    additional_info: {
      project_name: name,
      process_time: processTime,
      project_id: id,
      failure_reason: failureReason,
      project_type = "address_analysis",
    },
    notification_title: title,
    time_sent: sent,
  } = notification;

  function redirectToProject() {
    if (!name) {
      openTidioChat();
      return;
    }
    const path = title.match(/ready/i)
      ? ROUTE_PATHS.COMPLETED_PROJECT({ projectId: id, projectType: project_type })
      : ROUTE_PATHS.CREATED_PROJECT({ projectId: id, projectType: project_type });
    history.push(path);
  }

  const status = getStatus(title);
  const message = getMessage(status);

  function duration() {
    const timeArray = processTime.split(" ");
    return formatDuration({ [timeArray[1]]: parseInt(timeArray[0]) }, { locale });
  }

  const time = processTime ? `${t("Process time")} ${duration()}` : "";
  const notificationTitle = name ? `${t("Project")} "${name}" ${t(status)}` : t(title);

  function getNotificationDescription() {
    return status !== processStatuses.failed ? (
      <>
        {failureReason && <Styled.Text>{t(failureReason)}.</Styled.Text>}
        <Styled.Text>
          {t(message)} {time}
        </Styled.Text>
      </>
    ) : (
      <>
        <Styled.Text>{t(message)}</Styled.Text>
        {failureReason && <Styled.Text>{t(failureReason)}.</Styled.Text>}
      </>
    );
  }

  return (
    <Styled.Label onClick={redirectToProject}>
      <Styled.Time>{formatDistanceToNow(new Date(sent), { locale, addSuffix: true })}</Styled.Time>
      <Styled.NotificationTitle>{notificationTitle}</Styled.NotificationTitle>
      <Styled.Description lang={i18n.language}>{getNotificationDescription()}</Styled.Description>
    </Styled.Label>
  );
}
