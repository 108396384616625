import React, { forwardRef } from "react";
import ReactSelect from "react-select";
import WindowedSelect from "react-windowed-select";

import { customStyles } from "./style";

export const Select = forwardRef(function Select(props, ref) {
  const {
    testId = "",
    isLarge = false,
    isError = false,
    height = "35px",
    mobileHeight = "35px",
    specificStyle = {},
    ...rest
  } = props;
  const Component = isLarge ? WindowedSelect : ReactSelect;

  return (
    <Component
      ref={ref}
      classNamePrefix={testId}
      styles={{ ...customStyles, ...specificStyle }}
      isError={isError}
      height={height}
      mobileHeight={mobileHeight}
      {...rest}
    />
  );
});
