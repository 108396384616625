import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

import { QUERIES, Container as SContainer, COLORS } from "configs/styles/common";
import { FooterLogo, BigLogoIcon } from "assets/images/logo";

export const Footer = styled.footer`
  width: 100%;
  height: 235px;
  display: flex;
  align-items: center;
  background-color: ${COLORS.LIGHT.White};
  justify-content: center;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 235px;
    width: 420px;
    background-image: url(${FooterLogo});
    background-size: cover;

    @media ${QUERIES.upToLaptop} {
      width: 350px;
      height: 200px;
    }

    @media ${QUERIES.upToTablet} {
      display: none;
    }
  }

  @media ${QUERIES.upToLaptop} {
    height: 200px;
  }

  @media ${QUERIES.upToTablet} {
    height: auto;
  }
`;

export const Container = styled(SContainer)`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: inherit;

  @media ${QUERIES.upToTablet} {
    flex-direction: column;
    gap: 40px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.div`
  width: 260px;
  height: 90px;
  background-image: url(${BigLogoIcon});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  align-self: center;

  @media ${QUERIES.upToLaptop} {
    width: 200px;
  }

  @media ${QUERIES.upToTablet} {
    display: none;
  }
`;

export const NavList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  @media ${QUERIES.upToLaptop} {
    gap: 10px;
  }

  @media ${QUERIES.upToTablet} {
    gap: 14px;
  }
`;

export const ListItem = styled.li`
  font-size: 18px;
  line-height: 21px;
  font-weight: 300;

  @media ${QUERIES.upToLaptop} {
    font-size: 16px;
  }

  @media ${QUERIES.upToTablet} {
    font-size: 18px;
  }
`;

export const ListName = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;

  @media ${QUERIES.upToLaptop} {
    font-size: 18px;
  }

  @media ${QUERIES.upToTablet} {
    font-size: 18px;
  }
`;

export const Link = styled(RouterLink)`
  color: ${COLORS.DARK.Main};
  text-decoration: none;
  font-weight: 300;
  font-size: 18px;

  @media ${QUERIES.upToLaptop} {
    font-size: 16px;
  }

  @media ${QUERIES.upToTablet} {
    font-size: 18px;
  }
`;

export const Email = styled.a`
  color: ${COLORS.DARK.Main};
  text-decoration: none;
  font-weight: 300;
  font-size: 18px;

  @media ${QUERIES.upToLaptop} {
    font-size: 16px;
  }

  @media ${QUERIES.upToTablet} {
    font-size: 18px;
  }
`;

export const FileLink = styled(Link)`
  color: ${COLORS.DARK.Main};
  text-decoration: none;
  font-weight: 300;
  font-size: 18px;

  @media ${QUERIES.upToLaptop} {
    font-size: 16px;
  }

  @media ${QUERIES.upToTablet} {
    font-size: 18px;
  }
`;

export const SocialRow = styled.div`
  display: flex;
  gap: 10px;
`;

export const EmptyBlock = styled.div`
  width: 150px;
`;

export const SocialLink = styled(Email)``;

export const SocialIcon = styled.img``;

export const FooterHalf = styled.div`
  display: flex;
  align-items: flex-start;
`;
