import React, { Suspense } from "react";
import { hydrate, render } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";

import App from "./app/App";
import { GlobalStyle } from "./app/styles";
import { queryClient } from "./app/providers/react-query";
import { ErrorFallback } from "./components/presenters/common/ErrorFallback";
import { ToastsProvider } from "./app/providers/ToastsProvider";

import "configs/i18n/i18n.js";

const rootElement = document.querySelector("#root");

function Root() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <GlobalStyle />
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload(true)}>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
            <ToastsProvider />
          </QueryClientProvider>
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  );
}

if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}
