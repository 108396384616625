import Cookies from "js-cookie";
import React from "react";
import { Redirect, withRouter } from "react-router-dom";

import BaseRoute from "../BaseRoute/BaseRoute";

function PublicRoute({ ...rest }) {
  const isLoggedIn = Cookies.get("isLogin") === "true";

  if (isLoggedIn) {
    return <Redirect to={{ pathname: "/single-search", state: { from: rest.location } }} />;
  }
  return <BaseRoute {...rest} />;
}

export default withRouter(PublicRoute);
