import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";

import { getUserBasicInfo } from "./userAPI";

export function useUserBasicInfo() {
  const { data: userBasicInfo = {} } = useQuery({
    queryKey: [queryKeys.userBasic],
    queryFn: getUserBasicInfo,
  });

  const singleSearchProjectId = userBasicInfo?.single_search_project_id;

  return { userBasicInfo, singleSearchProjectId };
}
