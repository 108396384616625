import React from "react";

import { useIsDeviceType } from "services/hooks/useIsDeviceType";
import { useIsLoggedIn } from "services/hooks/useIsLoggedIn";

import { MobileHeader, PrivateLaptopHeader, PublicLaptopHeader } from "./components";

export function Header() {
  const { isTablet } = useIsDeviceType();
  const isLaptop = !isTablet;
  const isLoggedIn = useIsLoggedIn();

  return (
    <>
      {isTablet && <MobileHeader />}

      {isLaptop && !isLoggedIn && <PublicLaptopHeader />}

      {isLaptop && isLoggedIn && <PrivateLaptopHeader />}
    </>
  );
}
