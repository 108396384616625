import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

import { ROUTE_PATHS } from "app/providers/router";

import { Logo } from "../Logo";
import { NavigationLink } from "../NavigationLink";
import { LangSwitcher } from "../../../../common/LangSwitcher";
import * as Styled from "../../style";

export function PublicLaptopHeader() {
  const { t, i18n } = useTranslation();

  return (
    <Styled.HeaderWrapper>
      <Styled.Header>
        <Styled.MainNavigation>
          <Logo />
          <NavigationLink to={ROUTE_PATHS.HOME}>{t("Home")}</NavigationLink>
          <NavigationLink to={ROUTE_PATHS.PLATFORM}>{t("Platform")}</NavigationLink>
          <NavigationLink to={ROUTE_PATHS.ABOUT_US}>{t("About Us")}</NavigationLink>
        </Styled.MainNavigation>
        <Styled.MainNavigation smallGap>
          <NavigationLink to={ROUTE_PATHS.SIGN_IN}>{t("Sign In")}</NavigationLink>

          <Link to={ROUTE_PATHS.SIGN_UP} data-testid="nav-link">
            <Styled.GeStartedButton lang={i18n.language}>{t("Schedule demo")}</Styled.GeStartedButton>
          </Link>

          <Styled.LangWrapper>
            <LangSwitcher />
          </Styled.LangWrapper>
        </Styled.MainNavigation>
      </Styled.Header>
    </Styled.HeaderWrapper>
  );
}
