import { useMutation, useQueryClient } from "@tanstack/react-query";

import { queryKeys } from "app/providers/react-query";
import { showSuccessToast } from "services/utils/toast";

import { updateUserPicture } from "./userAPI";

export function useUpdateAvatar(successMessage) {
  const queryClient = useQueryClient();

  const { mutate: updateAvatar } = useMutation({
    mutationFn: updateUserPicture,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.avatar],
      });
      showSuccessToast(successMessage);
    },
  });

  return { updateAvatar };
}
